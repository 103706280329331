<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc"
          style="dispaly: flex; align-items: flex-start"
        >
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                clearable
                type="text"
                size="small"
                placeholder="请输入姓名"
              />
            </div>
            <span title="身份证号" class="searchboxItem ci-full flexcc">
              <span class="itemLabel" style="font-size: 14px; width: 100px"
                >身份证号:</span
              >
              <el-input
                v-model="idcard"
                clearable
                type="text"
                size="small"
                placeholder="请输入身份证号"
              />
            </span>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                clearable
                type="text"
                size="small"
                placeholder="请输入手机号"
              />
            </div>
            <div title="班级学习是否暂停" class="searchboxItem ci-full">
              <span class="itemLabel">班级学习是否暂停:</span>
              <el-select
                clearable
                size="small"
                v-model="isPause"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in WhetherToPause"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 15px">
            <div title="实名认证" class="searchboxItem ci-full">
              <span class="itemLabel">实名认证:</span>
              <el-select
                v-model="authenticationState"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in authenticationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="打印权限" class="searchboxItem ci-full">
              <span class="itemLabel">打印权限:</span>
              <el-select
                clearable
                size="small"
                v-model="allowPrint"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in allowPrintList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="closePrint()"
              >关闭未完成学员打印权限</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="openPrint()"
              >打开完成学员打印权限</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="handleAddUser()"
              v-if="auditState == 30 && projectSource == 30"
              >新增学员</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="120"
                fixed
              />
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="身份证号"
                align="center"
                show-overflow-tooltip
                prop="idcard"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
              />
                <el-table-column
                label="班级学习是否暂停"
                align="center"
                show-overflow-tooltip
                prop="isPause"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.isPause? '是':'否'
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="实名认证"
                align="center"
                show-overflow-tooltip
                prop="authentication"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("AUTHENTICATION", scope.row.authentication)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="入班时间"
                align="center"
                show-overflow-tooltip
                prop="operatorTime"
              />
              <el-table-column
                label="入班操作人"
                align="center"
                show-overflow-tooltip
                prop="operatorName"
              />
              <el-table-column
                label="打印权限"
                align="center"
                show-overflow-tooltip
                min-width="80"
              >
                <template slot-scope="scope">
                  <el-switch
                    :width="20"
                    v-model="scope.row.allowPrint"
                    @change="
                      getSwitchUsable(scope.row.allowPrint, scope.row.userId)
                    "
                  ></el-switch>
                  <span>{{ scope.row.allowPrint ? "开启" : "关闭" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="350px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    @click="doAddExamNum(scope.row.userId)"
                    >增加考试次数</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="doDel(scope.row.userId)"
                    >从本班移除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="Learningrecords(scope.row)"
                    >学习记录</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="CertificationRecord(scope.row, scope.$index)"
                    >认证记录</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="5%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a" style="height: 400px">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="学员姓名" prop="studentname" required>
            <el-input
              v-model="Form.studentname"
              type="text"
              size="small"
              placeholder="请输入学员姓名"
            />
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard" required>
            <el-input
              v-model="Form.idCard"
              type="text"
              size="small"
              placeholder="请输入身份证号"
              @input="
                (val) => {
                  getIdcard(val);
                }
              "
            />
            <div @click="showInfo" v-show="showtip" class="tip">
              {{ idcardmation || "" }}
            </div>
          </el-form-item>
          <el-form-item label="性别" prop="sex" required>
            <el-radio-group v-model="Form.sex" disabled>
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="mobile"
            class="Classitem"
            required
          >
            <el-input
              v-model="Form.mobile"
              type="text"
              size="small"
              placeholder="请输入联系电话"
            />
          </el-form-item>
          <el-form-item label="机构点名分组" prop="orgGroupId" required>
            <el-select size="small" v-model="Form.orgGroupId">
              <el-option
                v-for="item in orgGroupList"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="监管点名分组" prop="govGroupId">
            <el-select size="small" v-model="Form.govGroupId">
              <el-option
                v-for="item in govGroupList"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="cancleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="submit()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "studentInfo",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  data() {
    return {
      projectId: this.$route.query.projectId,
      projectSource: this.$route.query.projectSource,
      auditState: this.$route.query.auditState,
      projectName: this.$route.query.projectName,
      idcard: "",
      userName: "",
      mobile: "",
      allowPrint: "",
      isPause: "", // 用户班级学习是否暂停
      authenticationList: [],
      authenticationState: "",
      allowPrintList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "已打开",
        },
        {
          value: false,
          label: "未打开",
        },
      ],
      WhetherToPause: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      Form: {
        studentname: "",
        idCard: "",
        sex: "",
        mobile: "",
        orgGroupId: "",
        govGroupId: "",
        userId: "",
      },
      dialogTitle: "新增学员",
      dialogVisible: false,
      showtip: false,
      orgGroupList: [],
      govGroupList: [],
      rules: {
        studentname: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        orgGroupId: [
          { required: true, message: "请选择机构点名分组", trigger: "change" },
        ],
      },
      pageNumIndex: "",
    };
  },
  created() {
    this.getcompleteList();
  },
  methods: {
    // 获取学习状态&& 学历
    getcompleteList() {
      const authentication = this.$setDictionary("AUTHENTICATION", "list");
      const authenticationList = [{ value: "", label: "全部" }];
      for (const key in authentication) {
        authenticationList.push({
          value: key,
          label: authentication[key],
        });
      }
      this.authenticationList = authenticationList;
    },
    getData(pageNum = 1) {
      this.pageNumIndex = pageNum;
      console.log(this.pageNumIndex);
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        idcard: this.idcard,
        userName: this.userName,
        mobile: this.mobile,
        allowPrint: this.allowPrint,
        authentication: this.authenticationState,
        isPause: this.isPause
      };
      this.pageNumIndex = params.pageNum;
      this.doFetch({
        url: "biz/project/queryUserList",
        params,
        pageNum,
      });
    },
    openPrint() {
      this.$post("/biz/project/openFinishedUserPrint", {
        projectId: this.projectId,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: ret.message,
            });
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    closePrint() {
      this.$post("/biz/project/closeNoFinishedUserPrint", {
        projectId: this.projectId,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: ret.message,
            });
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    //增加考试次数
    doAddExamNum(userId) {
      this.$post("/biz/project/addExamNum", {
        userId,
        projectId: this.projectId,
      })
        .then((res) => {
          if (res.status == "0") {
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 删除
    doDel(userId) {
      let params = {
        userId: userId,
        projectId: this.projectId,
      };
      this.$confirm("是否将该学员在本班中移除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/projectUser/removeCourseUser", params).then(
            (res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.getData(-1);
              }
            }
          );
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        });
    },
    /* 打印权限 */
    getSwitchUsable(allowPrint, userId) {
      this.$post("/biz/project/changeUserPrintState", {
        allowPrint,
        projectId: this.projectId,
        userId,
      })
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
        })
        .catch((err) => {
          return;
        });
    },
    Learningrecords(row) {
      sessionStorage.setItem("row", JSON.stringify(row));
      sessionStorage.setItem("projectIdYw", this.projectId);
      this.$router.push({
        path: "/web/Learningrecords",
        query: {
          stu: "JG",
        },
      });
    },
    //认证记录
    CertificationRecord(row, index) {
      console.log(this.projectName);
      let info = {
        ...row,
        projectId: this.projectId,
        projectName: this.projectName,
        pageNum: this.pageNumIndex == -1 ? 1 : this.pageNumIndex,
        pageSize: this.pageSize,
        idcard: this.idcard,
        userName: this.userName,
        mobile: this.mobile,
        allowPrint: this.allowPrint,
        authentication: this.authenticationState,
        userIndex: index,
      };
      this.$router.push({
        path: "/web/studentInfoCertificationRecord",
        query: {
          info: JSON.stringify(info),
        },
      });
    },
    // 新增学员
    handleAddUser() {
      this.Form.studentname = "";
      this.Form.idCard = "";
      this.Form.sex = "";
      this.Form.mobile = "";
      this.Form.orgGroupId = "";
      this.Form.govGroupId = "";
      this.Form.userId = "";
      this.getOrgGroupList();
      this.getGovGroupList();
      this.dialogTitle = "新增学员";
      this.dialogVisible = true;
    },
    doClose() {
      this.dialogVisible = false;
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.blurcard(newValue);
        this.$post("/biz/user/queryUserByIdCard", {
          idcard: this.Form.idCard,
        })
          .then((res) => {
            if (!res.data) {
              this.showtip = false;
            } else {
              this.showtip = true;
              this.idcardObj = res.data;
              this.idcardmation =
                res.data.userName +
                  "/" +
                  res.data.idcard +
                  "/" +
                  res.data.mobile || "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.showtip = false;
      }
    },
    blurcard(e) {
      let sex = "";
      if (parseInt(e.substr(16, 1)) % 2 == 1) {
        //男
        sex = "1";
      } else {
        //女
        sex = "2";
      }
      this.Form.sex = sex;
    },
    showInfo() {
      this.showtip = false;
      this.Form.studentname = this.idcardObj.userName;
      this.Form.idCard = this.idcardObj.idcard;
      this.Form.sex = this.idcardObj.sex;
      this.Form.mobile = this.idcardObj.mobile;
      this.Form.userId = this.idcardObj.userId;
    },
    submit() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          const parmar = {
            projectId: this.projectId,
            userName: this.Form.studentname,
            idcard: this.Form.idCard,
            sex: this.Form.sex,
            mobile: this.Form.mobile,
            orgGroupId: this.Form.orgGroupId,
            govGroupId: this.Form.govGroupId,
          };
          if (this.Form.userId) {
            parmar.userId = this.Form.userId;
          }
          this.$post("/biz/projectUser/add", parmar)
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: ret.message,
                  type: "success",
                });
                this.dialogVisible = false;
                this.getData();
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          return false;
        }
      });
    },
    cancleForm() {
      this.dialogVisible = false;
    },
    getOrgGroupList() {
      this.$post("/biz/roll/group/list", {
        projectId: this.projectId,
        groupType: "10",
      }).then((ret) => {
        if (ret.status == 0 && ret.data.length > 0) {
          this.orgGroupList = ret.data;
          this.Form.orgGroupId = ret.data[0].groupId;
        }
      });
    },
    getGovGroupList() {
      this.$post("/biz/roll/group/list", {
        projectId: this.projectId,
        groupType: "20",
      }).then((ret) => {
        if (ret.status == 0 && ret.data.length > 0) {
          this.govGroupList = ret.data;
          this.Form.govGroupId = ret.data[0].groupId;
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    goHerf() {
      this.$router.push({
        path: "/web/classListCtrY",
        query: {
          refresh: false,
        },
      });
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.projectId = val.query.projectId;
          this.getData(-1);
        } else {
          this.getData(val.query.pageNum);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped></style>
